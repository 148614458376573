import { useAuth } from "@clerk/clerk-react";
import {
  BadgeAlert,
  BadgeCheck,
  Folder,
  PackageSearch,
  Printer,
  Search,
  Settings,
  Users,
} from "lucide-react";
import { PropsWithChildren, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ICheckInputSingleLineViewModel } from "../../../application/viewModels/CheckInputSingleLineViewModel";
import { useProjectStore, useUserStore } from "../../../stores/project-store";
import { ChecklistContext } from "../../context/ChecklistManager";
import LoginPopup from "../../general/App/login-popup";
import LogoSvg from "../../general/App/logo-svg";
import useZebraLabelPrint from "../../hooks/useZebraLabelPrint";
import UserAvatar from "../user-avatar";

export default function LayoutV2({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { selectedProject } = useProjectStore();
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const { loggedInUser } = useUserStore();
  const { userId } = useAuth();
  const isAdminUserId = userId === process.env.REACT_APP_ADMIN_USER_ID;
  const { checklist } = useContext(ChecklistContext);

  const printCheck = checklist?.groups
    ?.find((group) => group.checks.find((c) => c.title.includes(":stempel:")))
    ?.checks.find((c) =>
      c.title.includes(":stempel:"),
    ) as ICheckInputSingleLineViewModel;
  const showPrintButton = !!printCheck;
  const { handlePrint, allowPrint } = useZebraLabelPrint();

  return (
    <div className="md:flex w-full bg-surface-highest dark:bg-surface-lowest">
      <LoginPopup
        open={loginPopupOpen}
        onClose={() => setLoginPopupOpen(false)}
      />

      <div className="md:flex hidden w-20 h-screen pt-4 bg-surface-highest dark:bg-surface-lowest justify-between items-center flex-col">
        <div>
          <LogoSvg />
        </div>
        <div className="space-y-8">
          <div
            className="p-3 cursor-pointer group hover:dark:bg-surface-low hover:bg-surface-high rounded-full duration-150 ease-out"
            onClick={() => navigate(`/`)}
          >
            <Search className="w-5 h-5 text-muted-foreground group-hover:text-primary duration-150 ease-out" />
          </div>
          <div
            onClick={() => navigate(`/product-checklist-select`)}
            className="hidden p-3 cursor-pointer group hover:dark:bg-surface-low hover:bg-surface-high rounded-full duration-150 ease-out"
          >
            <PackageSearch className="w-5 h-5 text-muted-foreground group-hover:text-primary duration-150 ease-out" />
          </div>
          <div
            onClick={() => navigate(`/settings`)}
            className="p-3 cursor-pointer group hover:dark:bg-surface-low hover:bg-surface-high rounded-full duration-150 ease-out"
          >
            <Settings className="w-5 h-5 text-muted-foreground group-hover:text-primary duration-150 ease-out" />
          </div>
          {isAdminUserId && (
            <>
              <div
                onClick={() => setLoginPopupOpen(true)}
                className="p-3 cursor-pointer group hover:dark:bg-surface-low hover:bg-surface-high rounded-full duration-150 ease-out"
              >
                <Users className="w-5 h-5 text-muted-foreground group-hover:text-primary duration-150 ease-out" />
              </div>
              {loggedInUser?.userId && (
                <div className="p-3">
                  <UserAvatar name={false} id={loggedInUser?.userId} />
                </div>
              )}
            </>
          )}
        </div>

        <div className="w-full p-2 1h-[86px] h-fit relative">
          {selectedProject && showPrintButton && (
            <div
              onClick={() => handlePrint(true, printCheck)}
              className="hidden cursor-pointer mb-2 1border w-full rounded-lg justify-between dark:bg-surface-container bg-surface-high relative flex flex-col items-center"
            >
              <div className="h-full pt-3 pb-1.5">
                <BadgeCheck
                  className={
                    allowPrint
                      ? "text-green-400 w-6 h-6"
                      : "text-neutral-400 w-6 h-6"
                  }
                />
              </div>
              <span className="w-full leading-none flex items-center justify-center py-1.5 rounded-b-lg text-center dark:bg-surface-high bg-surface-low text-xs mt-1 text-muted-foreground">
                {/* <span className="truncate overflow-hidden w-12 ">Klokvel</span> */}
                <Printer className="w-4 h-4" />
              </span>
            </div>
          )}
          {selectedProject && showPrintButton && (
            <div
              onClick={() => handlePrint(false, printCheck)}
              className="hidden cursor-pointer mb-8 1border w-full rounded-lg justify-between dark:bg-surface-container bg-surface-high relative flex flex-col items-center"
            >
              <div className="h-full pt-3 pb-1.5">
                <BadgeAlert
                  className={
                    allowPrint
                      ? "text-red-400 w-6 h-6"
                      : "text-neutral-400 w-6 h-6"
                  }
                />
              </div>
              <span className="w-full leading-none flex items-center justify-center py-1.5 rounded-b-lg text-center dark:bg-surface-high bg-surface-low text-xs mt-1 text-muted-foreground">
                {/* <span className="truncate overflow-hidden w-12 ">Klokvel</span> */}
                <Printer className="w-4 h-4" />
              </span>
            </div>
          )}
          {selectedProject && (
            <div
              onClick={() => navigate(`/project/${selectedProject.id}`)}
              className="cursor-pointer 1border w-full rounded-lg justify-between dark:bg-surface-container bg-surface-high relative flex flex-col items-center"
            >
              <div className="h-full pt-3 pb-1.5">
                <Folder className="text-pink-400 w-6 h-6" />
              </div>
              <span className="w-full leading-none flex items-center justify-center py-1.5 rounded-b-lg text-center dark:bg-surface-high bg-surface-low text-xs mt-1 text-muted-foreground">
                <span className="truncate overflow-hidden w-12 ">
                  {selectedProject?.code}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="w-full h-fit">{children}</div>

      <div className="fixed bg-surface-container border-t bottom-0 z-40 md:hidden w-screen h-14 1bg-surface-highest 1dark:bg-surface-lowest justify-between items-center">
        <div className="space-x-8 flex items-center w-full justify-center h-full">
          <div
            className="p-3 cursor-pointer group hover:dark:bg-surface-low hover:bg-surface-high rounded-full duration-150 ease-out"
            onClick={() => navigate(`/`)}
          >
            <Search className="w-5 h-5 text-muted-foreground group-hover:text-primary duration-150 ease-out" />
          </div>
          <div
            onClick={() => navigate(`/product-checklist-select`)}
            className="hidden p-3 cursor-pointer group hover:dark:bg-surface-low hover:bg-surface-high rounded-full duration-150 ease-out"
          >
            <PackageSearch className="w-5 h-5 text-muted-foreground group-hover:text-primary duration-150 ease-out" />
          </div>
          <div
            onClick={() => navigate(`/settings`)}
            className="p-3 cursor-pointer group hover:dark:bg-surface-low hover:bg-surface-high rounded-full duration-150 ease-out"
          >
            <Settings className="w-5 h-5 text-muted-foreground group-hover:text-primary duration-150 ease-out" />
          </div>
          {isAdminUserId && (
            <>
              <div
                onClick={() => setLoginPopupOpen(true)}
                className="p-3 cursor-pointer group hover:dark:bg-surface-low hover:bg-surface-high rounded-full duration-150 ease-out"
              >
                <Users className="w-5 h-5 text-muted-foreground group-hover:text-primary duration-150 ease-out" />
              </div>
              {loggedInUser?.userId && (
                <div className="p-3">
                  <UserAvatar name={false} id={loggedInUser?.userId} />
                </div>
              )}
            </>
          )}
        </div>

        <div className="p-2 w-fit h-fit absolute right-2 top-0">
          {selectedProject && (
            <div
              onClick={() => navigate(`/project/${selectedProject.id}`)}
              className="cursor-pointer 1border w-full rounded-lg justify-between dark:bg-surface-container bg-surface-high relative flex flex-col items-center"
            >
              <div className="h-full p-2 bg-surface-high rounded-md">
                <Folder className="text-pink-400 w-6 h-6" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
