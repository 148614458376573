import { ISignChecklistRequest } from "../../../infrastructure/api/endpoints/common/request.types";
import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { IResult } from "../../results/Result";

export class SignChecklistCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(request: ISignChecklistRequest): Promise<IResult> {
    return await this._checklistRepository.signChecklist(request);
  }
}
