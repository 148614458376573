import {
  ArrowRight,
  CheckCircle2,
  Circle,
  Loader2,
  PackagePlus,
  ScanLine,
  Search,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { z } from "zod";
import {
  checklistsHooks,
  inpectionsHooks,
  projectsHooks,
  templateChecklistsHooks,
} from "../../../api";
import { schemas } from "../../../api/schema";
import { useScanner } from "../../hooks/useMemor10Scanner";
import ScannerPopup from "../../molecules/ScannerPopup";
import LayoutV2 from "../../molecules/layout-v2/layout-v2";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/accordion";
import { Input } from "../../ui/input";
import { useToast } from "../../ui/use-toast";
// import { ApiOf, ZodiosResponseByPath } from "@zodios/core";
// export type Checklist = ZodiosResponseByPath<
//   ApiOf<typeof api.templateChecklists>,
//   "get",
//   "/templates/checklists/by-product-label/:productLabel"
// >;

export type TemplateChecklist = z.infer<
  typeof schemas.Checklab_WebApi_Features_Templates_Dtos_TemplateChecklistDto
>;

export enum ChecklistState {
  Accepted = 1,
  ApprovalRequired = 2,
  InComplete = 3,
  //TODO: add enum value for checklist that have a rejected check (also for back-end).
  // Rejected = 4
}

const ChecklistStateLabelMap = {
  [ChecklistState.Accepted]: "Geaccepteerd",
  [ChecklistState.ApprovalRequired]: "Aftekenen",
  [ChecklistState.InComplete]: "Actief",
};

enum InspectionsQueryOrdeBy {
  DefaultOrder = 0,
  CreatedAtAscending,
  CreatedAtDescending,
  UpdatedAtAscending,
  UpdatedAtDescending,
  AcceptedAtAscending,
  AcceptedAtDescending,
  TitleAscending,
  TitleDescending,
  SerialNumberAscending,
  SerialNumberDescending,
}

export const ProductChecklistSelectPage: React.FC = () => {
  const navigate = useNavigate();
  const { projectId, productLabel } = useParams<{
    projectId: string | undefined;
    productLabel: string | undefined;
  }>();
  // const [searchValue, setSearchValue] = useState("");
  const [productLabelSearchValue, setProductLabelSearchValue] = useState(
    productLabel || "",
  );
  const [debouncedProductLabelSearchValue] = useDebounce(
    productLabelSearchValue,
    300,
  );
  const [serialNumberSearchValue, setSerialNumberSearchValue] = useState("");
  const [debouncedSerialNumberSearchValue] = useDebounce(
    serialNumberSearchValue,
    300,
  );

  const templateChecklistsSearchEnabled =
    debouncedProductLabelSearchValue !== "";
  const checklistsSearchEnabled = debouncedSerialNumberSearchValue !== "";

  const { data: templateChecklists, isLoading: isLoadingTemplateChecklists } =
    templateChecklistsHooks.useGet(
      "/templates/checklists/by-product-label/:productLabel",
      { params: { productLabel: debouncedProductLabelSearchValue } },
      { enabled: templateChecklistsSearchEnabled },
    );

  const { data: templateChecklistsTemplates } = templateChecklistsHooks.useGet(
    "/templates/checklists",
    { queries: { page: 1, size: 100 } },
  );

  const { data: checklists, isLoading: isLoadingChecklists } =
    inpectionsHooks.useGet(
      "/inspections/search",
      {
        queries: {
          serialNumber: debouncedSerialNumberSearchValue,
          orderBy: InspectionsQueryOrdeBy.UpdatedAtDescending,
          page: 1,
          size: 25,
        },
      },
      { enabled: checklistsSearchEnabled },
    );

  const { data: recentChecklists } = inpectionsHooks.useQuery(
    "/inspections/recent",
    {
      queries: {
        page: 1,
        size: 25,
      },
    },
  );

  const { mutate: postChecklist } = checklistsHooks.usePost("/checklists");

  const { mutate: putChecklistToProject } = projectsHooks.usePut(
    "/projects/checklists",
  );

  function handleScanner() {
    // // Check if product label and serialnumber is entered.
    // const [serialNumber, productLabel] = searchValue.split("|");
    // if (!serialNumber || !productLabel) {
    //   return;
    // }
    // setSerialNumberSearchValue(serialNumber);
    // setProductLabelSearchValue(productLabel);
  }

  function handleTemplateChecklistClick(templateChecklist: TemplateChecklist) {
    if (serialNumberSearchValue === "") {
      return;
    }

    postChecklist(
      {
        serialNumber: serialNumberSearchValue,
        productLabel: templateChecklist.title ?? "undefined",
        templateChecklistId: templateChecklist.id,
        projectId: projectId === undefined ? undefined : Number(projectId),
      },
      {
        onSuccess: (data) => {
          navigate(`/checklist/${data.checklistUid}`);
        },
      },
    );
  }

  useEffect(() => {
    document.getElementById("123")?.addEventListener("scroll", function () {
      if (
        document.activeElement &&
        document.activeElement.tagName === "INPUT"
      ) {
        (document.activeElement as HTMLInputElement).blur();
      }
    });
  }, []);

  const [scanActive, setScanActive] = useState(false);
  const {
    scan,
    scanResult: scanResultUseScanner,
    scanMethod,
    reset,
    overrideScanMethod,
    setDefaultScanMethod,
    resetCamera,
    scanning,
  } = useScanner("checklistSearchScanReader");

  const handleScannerScan = async () => {
    if (scanMethod === "text") {
      setScanActive(true);
      return;
    }

    if (scanMethod === "camera") {
      setScanActive(true);
    }

    try {
      await scan();
    } catch (e: unknown) {
      const { message } = e as Error;

      alert(`${message}`);
    }
  };

  const { toast } = useToast();

  const navigateToChecklist = async (result: string) => {
    const code = result;

    if (code.length < 1) {
      setScanActive(false);
      resetCamera();

      toast({
        title: "Invalid scan result",
        variant: "destructive",
      });

      return;
    }

    resetCamera();
    setScanActive(false);

    setSerialNumberSearchValue(code);
  };

  return (
    <LayoutV2>
      {scanMethod === "camera" && (
        <ScannerPopup
          confirmLabel="Checklist zoeken"
          readerId={"checklistSearchScanReader"}
          onManualInput={() => overrideScanMethod("text")}
          onDeleteResult={reset}
          open={scanActive}
          data={scanResultUseScanner}
          onConfirm={() => {
            navigateToChecklist(scanResultUseScanner ?? "");
          }}
          onClose={() => {
            resetCamera();
            setScanActive(false);
          }}
        />
      )}

      <div className="px-2 pt-2">
        <div className="relative mb-4 w-full flex items-center mr-3 sm:mr-0">
          <Search className="absolute left-5 w-5 h-5 text-primary" />
          <form
            className="w-full"
            onSubmit={(e) => {
              e.preventDefault();
              (document.activeElement as HTMLInputElement).blur();
            }}
          >
            <Input
              // If scanner input type is wedge use false else true.
              autoFocus={false}
              value={serialNumberSearchValue}
              onChange={(e) => {
                setSerialNumberSearchValue(e.target.value);
              }}
              className="rounded-xl text-base border-0 border-zinc-700 pl-14 h-12 pr-4 py-0 bg-surface-lowest dark:bg-surface-highest placeholder:text-muted-foreground"
              placeholder={"Checklist zoeken..."}
            />
          </form>
          {/* TODO: hide only when wedge mode enabled... */}
          <div className="flex p-2 h-full absolute right-0">
            <button
              className="border rounded-lg h-full px-2 bg-primary text-primary-foreground"
              onClick={handleScannerScan}
            >
              <ScanLine className="w-4 h-4 text-primary-foreground" />
            </button>
          </div>
        </div>

        {/* <div className="mb-4 flex items-center">
          <Input
            // autoFocus
            value={serialNumberSearchValue}
            onChange={(e) => {
              setSerialNumberSearchValue(e.target.value);
            }}
            className="border-color border-r-0 rounded-r-none rounded-l-lg h-12"
            placeholder="Checklists zoeken..."
          />
          <button
            className="border border-primary rounded-lg rounded-l-none px-4 h-12 bg-primary text-primary-foreground"
            onClick={handleScannerScan}
          >
            <ScanLine className="w-4 h-4 text-primary-foreground" />
          </button>
        </div> */}

        <Accordion
          // defaultValue="item-1"
          collapsible
          type="single"
          className="mb-4 border-dashed 1hidden"
          disabled={
            templateChecklistsTemplates?.results
              ? templateChecklistsTemplates?.results?.length < 1
              : true
          }
        >
          <AccordionItem value="item-1" className="border-b-0">
            <AccordionTrigger
              className={`border p-4 rounded-lg 1border-t-0 w-full items-center hover:no-underline justify-between text-left ${
                templateChecklistsTemplates?.results &&
                templateChecklistsTemplates?.results?.length > 0
                  ? "bg-card border-transparent text-card-foreground"
                  : "bg-background border-color text-muted-foreground opacity-60"
              }`}
            >
              <div className="w-full">
                <span className={`text-sm font-semibold block`}>
                  Nieuwe checklist starten
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent className="border-x-2 border-b-2 border-card rounded-b-lg mt-[-8px] pb-3 1pt-2 border-t-8">
              {templateChecklistsTemplates?.results?.map((checklist, idx) => (
                <div
                  onClick={() => {
                    handleTemplateChecklistClick(checklist);
                  }}
                  key={`${checklist.id}-${idx}`}
                  className={`mt-3 1border rounded-lg px-3 py-1.5 h-fit flex items-start 1bg-card ${
                    serialNumberSearchValue === ""
                      ? "opacity-50 cursor-not-allowed"
                      : "opacity-100 cursor-pointer"
                  }`}
                >
                  <div className="pt-0.5">
                    <PackagePlus className="w-4 h-4 mr-2 text-muted-foreground" />
                  </div>
                  <div>
                    <span className="block text-sm font-semibold text-card-foreground">
                      {checklist.title}
                    </span>
                    <span className="block text-xs font-normal text-muted-foreground/60">
                      Nieuwe inspectie starten
                    </span>
                  </div>

                  <div className="ml-auto pt-0.5">
                    <ArrowRight className="w-4 h-4 text-muted-foreground" />
                  </div>
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div>
        <span className="pb-1 pl-4 text-sm font-semibold block text-muted-foreground">
          Zoekresultaten {checklists?.results?.length}
        </span>
      </div>
      <div className="px-2">
        <div
          className="1border 1p-4 1rounded-lg overflow-scroll pt-4 md:h-[calc(100vh-98px)] h-[calc(100vh-152px)]"
          id="123"
          // style={{ height: "calc(100vh - 98px)" }}
        >
          {/* {debouncedSerialNumberSearchValue === "" && (
            <div className="w-full h-36 bg-card/50 1border rounded-lg flex items-center justify-center">
              <span className="text-sm text-muted-foreground/60">
                Begin te typen om te zoeken...
              </span>
            </div>
          )}

          {isLoadingChecklists && checklistsSearchEnabled && (
            <div className="w-full h-36 bg-card/50 border rounded-lg flex items-center justify-center">
              <Loader2 className="w-4 h-4 text-muted-foreground animate-spin" />
            </div>
          )}

          {debouncedSerialNumberSearchValue !== "" &&
            !isLoadingChecklists &&
            !checklists?.results && (
              <div className="w-full h-36 bg-card/50 border rounded-lg flex items-center justify-center">
                <span className="text-sm text-muted-foreground/60">
                  Geen checklists gevonden...
                </span>
              </div>
            )} */}

          {debouncedSerialNumberSearchValue === "" &&
            recentChecklists &&
            recentChecklists?.results?.map((checklist) => (
              <div
                onClick={() => {
                  if (projectId === undefined) {
                    navigate(`/checklist/${checklist.id}`);

                    return;
                  }

                  // If project id is present it means we want to add the selected checklist to the project.
                  putChecklistToProject(
                    {
                      projectId: Number(projectId),
                      checklistId: checklist.id,
                    },
                    {
                      onSuccess: () => {
                        navigate(`/project/${projectId}`);
                      },
                      onError: (data) => {
                        alert(data.message);
                      },
                    },
                  );
                }}
                key={checklist.id}
                className={`1border rounded-lg px-3 py-3 mb-3 bg-card flex items-start h-fit cursor-pointer`}
              >
                <div className="pt-0.5">
                  <span className="flex items-center text-sm text-muted-foreground font-medium">
                    {checklist.state === 1 && (
                      <CheckCircle2 className="h-4 w-4 mr-2 dark:text-emerald-400 text-green-500" />
                    )}
                    {checklist.state === 2 && (
                      <Circle className="h-4 w-4 mr-2 text-muted-foreground" />
                    )}
                    {checklist.state === 3 && (
                      <span className="relative mr-2 flex items-center justify-center text-yellow-500">
                        <div className="border-[3px] border-card w-[14px] h-[14px] absolute bg-yellow-500 rounded-full" />
                        <Loader2 className="animate-[spin_3s_linear_infinite] z-10 w-5 h-5 text-yellow-500/50" />
                      </span>
                    )}
                  </span>

                  {/* <PackageCheck className="w-4 h-4 mr-2 text-muted-foreground/60" /> */}
                </div>
                <div>
                  <span className="block text-sm font-semibold text-card-foreground">
                    {checklist.serialNumber}{" "}
                  </span>
                  <span className="mt-1 block text-xs text-muted-foreground/60 font-normal">
                    {checklist.title}
                  </span>
                </div>
                <span className="ml-auto text-sm text-muted-foreground/60 font-medium pt-0.5">
                  {checklist.percentage?.toFixed()}%
                </span>
              </div>
            ))}

          {checklists?.results?.map((checklist) => (
            <div
              onClick={() => {
                if (projectId === undefined) {
                  navigate(`/checklist/${checklist.id}`);

                  return;
                }

                // If project id is present it means we want to add the selected checklist to the project.
                putChecklistToProject(
                  {
                    projectId: Number(projectId),
                    checklistId: checklist.id,
                  },
                  {
                    onSuccess: () => {
                      navigate(`/project/${projectId}`);
                    },
                    onError: (data) => {
                      alert(data.message);
                    },
                  },
                );
              }}
              key={checklist.id}
              className={`rounded-lg px-3 py-3 mb-3 bg-card flex items-start h-fit cursor-pointer`}
            >
              <div className="pt-0.5">
                <span className="flex items-center text-sm text-muted-foreground font-medium">
                  {checklist.state === 1 && (
                    <CheckCircle2 className="h-4 w-4 mr-2 dark:text-emerald-400 text-green-500" />
                  )}
                  {checklist.state === 2 && (
                    <Circle className="h-4 w-4 mr-2 text-muted-foreground" />
                  )}
                  {checklist.state === 3 && (
                    <span className="relative mr-2 flex items-center justify-center text-yellow-500">
                      <div className="border-[3px] border-card w-[14px] h-[14px] absolute bg-yellow-500 rounded-full" />
                      <Loader2 className="animate-[spin_3s_linear_infinite] z-10 w-5 h-5 text-yellow-500/50" />
                    </span>
                  )}
                </span>

                {/* <PackageCheck className="w-4 h-4 mr-2 text-muted-foreground/60" /> */}
              </div>
              <div>
                <span className="block text-sm font-semibold text-card-foreground">
                  {checklist.serialNumber}{" "}
                </span>
                <span className="mt-1 block text-xs text-muted-foreground/60 font-normal">
                  {checklist.title}
                </span>
              </div>
              <span className="ml-auto text-sm text-muted-foreground/60 font-medium pt-0.5">
                {checklist.percentage?.toFixed()}%
              </span>
            </div>
          ))}
        </div>
      </div>
    </LayoutV2>
  );
};
