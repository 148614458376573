import { makeAutoObservable, runInAction } from "mobx";
import { JoinChecklistCommand } from "../../core/commands/check/JoinChecklistCommand";
import { LeaveChecklistCommand } from "../../core/commands/check/LeaveChecklistCommand";
import { SignChecklistCommand } from "../../core/commands/check/SignChecklistCommand";
import { SignUndoChecklistCommand } from "../../core/commands/check/SignUndoChecklistCommand";
import { ChecklistEntity } from "../../core/domain/entities/checklist/ChecklistEntity";
import { ChecklistBySerialQuery } from "../../core/queries/ChecklistBySerialQuery";
import { CheckMapper } from "../../infrastructure/api/mappers/CheckMapper";
import { OriginReference } from "./CheckBaseViewModel";
import { SectionViewModel } from "./SectionViewModel";

export interface IChecklistViewModel {
  uid: string;
  id: number;
  serialNumber: string;
  title: string;
  specialFunctions: string;
  signed: boolean;
  state: any;
  groups: SectionViewModel[];
  locations: {
    id: number;
    name: string;
    description: string;
    workspaceId: 0;
  }[];
  percentage: number;
  getPercentage: (filtered?: boolean) => number;
  sign: () => Promise<void>;
  signRedo: () => Promise<void>;
  unfilteredSections: SectionViewModel[];
  leaveChecklist: () => Promise<void>;
  createdBy: OriginReference | null;
  acceptedBy: OriginReference | null;
}

export class ChecklistViewModel implements IChecklistViewModel {
  private _getChecklistByIdQuery = new ChecklistBySerialQuery();
  private _signChecklistCommand = new SignChecklistCommand();
  private _signUndoChecklistCommand = new SignUndoChecklistCommand();
  private _joinChecklistCommand: JoinChecklistCommand =
    new JoinChecklistCommand();
  private _leaveChecklistCommand: LeaveChecklistCommand =
    new LeaveChecklistCommand();

  public uid: string = "";
  public id: number = 0;
  public serialNumber: string = "";
  public specialFunctions: string = "";
  public title: string = "";
  public signed: boolean = false;
  public state: any;
  public percentage = 0;
  public locations: {
    id: number;
    name: string;
    description: string;
    workspaceId: 0;
  }[] = [];
  public createdBy: OriginReference | null = null;
  public acceptedBy: OriginReference | null = null;

  private _sections: SectionViewModel[] = [];

  constructor(serialNumber: string, language: string) {
    makeAutoObservable(this);

    this.setup(serialNumber, language);
  }

  private async setup(serialNumber: string, language: string): Promise<void> {
    const result = await this._getChecklistByIdQuery.handle(
      serialNumber,
      language,
    );

    if (result.isSuccessful) {
      const { content } = result;

      this.handleUpdateChecklist(content);

      await this._joinChecklistCommand.handle(this.id, (check) => {
        // TODO: serialNumber is the uid of the checklist actually. Change naming.

        const updatedCheck = this.groups
          .find((group) => group.checks.map((c) => c.uid).includes(check.uid))
          ?.checks.find((c) => c.uid === check.uid);

        if (updatedCheck) {
          updatedCheck.updateCheck(CheckMapper.fromDTO({ ...check }));
        }

        console.log({ updatedCheck, check });

        // this.handleUpdateChecklist(check);
      });
    }
  }

  private handleUpdateChecklist(checklist: ChecklistEntity): void {
    runInAction(async () => {
      const locations = Array.from(
        new Set(
          checklist.groups
            .map((s) => s.checks.map((c) => c.locations))
            .flat()
            .flat(),
        ),
      );

      this.uid = checklist.uid;
      this.id = checklist.id;
      this.title = checklist.title;
      this.serialNumber = checklist.serialNumber;
      this.signed = checklist.signed;
      this.state = checklist.state;
      this.locations = locations;
      this.createdBy = checklist.createdBy;
      this.acceptedBy = checklist.acceptedBy;
      this.specialFunctions = checklist.specialFunctions;
      this._sections = checklist.groups.map(
        (s) => new SectionViewModel(s, checklist.uid),
      );
    });
  }

  public async leaveChecklist(): Promise<void> {
    await this._leaveChecklistCommand.handle(this.serialNumber);
  }

  public get unfilteredSections(): SectionViewModel[] {
    return this._sections;
  }

  public get groups(): SectionViewModel[] {
    return this._sections;
  }

  public getPercentage(filtered: boolean = false): number {
    if (this.groups.map((s) => s.checks).flat().length === 0) return 0;

    const allRequiredChecks = (filtered ? this.groups : this.unfilteredSections)
      .map((s) => (filtered ? s.checks : s.unfilteredChecks))
      .flat()
      .filter((c) => c.required);
    const allChecksFinished = allRequiredChecks.filter((c) => c.finished);

    if (allRequiredChecks.length === 0) return 100;

    return Math.round(
      (allChecksFinished.length / allRequiredChecks.length) * 100,
    );
  }

  public async sign(): Promise<void> {
    const { isNotSuccessful, failure } =
      await this._signChecklistCommand.handle({
        checklistUid: this.uid,
      });

    if (isNotSuccessful) {
      throw Error(`${failure?.failureReason}: ${failure?.message}`);
    }

    runInAction(() => {
      this.signed = true;

      const x = { state: { selectedUser: { id: "" } } };

      const userId = //@ts-ignore
        window.Clerk.user.id === process.env.REACT_APP_ADMIN_USER_ID
          ? JSON.parse(
              localStorage.getItem("user-storage") || JSON.stringify(x),
            ).state.loggedInUser?.userId
          : //@ts-ignore
            window.Clerk.user.id;

      this.acceptedBy = {
        value: userId,
        type: 1,
      };
    });
  }

  public async signRedo(): Promise<void> {
    const { isNotSuccessful, failure } =
      await this._signUndoChecklistCommand.handle({
        checklistUid: this.uid,
      });

    if (isNotSuccessful) {
      throw Error(`${failure?.failureReason}: ${failure?.message}`);
    }

    runInAction(() => {
      this.signed = false;
    });
  }
}
